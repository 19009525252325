@mixin xs-size-max {
  @media screen and (max-width: 767px) {
    @content;
  }
}

@mixin xs-size-min {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin sm-size-max {
  @media screen and (max-width: 991px) {
    @content;
  }
}

@mixin sm-size-min {
  @media screen and (min-width: 992px) {
    @content;
  }
}

@mixin md-size-max {
  @media screen and (max-width: 1199px) {
    @content;
  }
}

@mixin md-size-min {
  @media screen and (min-width: 1200px) {
    @content;
  }
}

@mixin lg-size-max {
  @media screen and (max-width: 1440px) {
    @content;
  }
}

@mixin lg-size-min {
  @media screen and (min-width: 1441px) {
    @content;
  }
}

@mixin xs-size-min-sm-size-max {
  @media screen and (min-width: 768px) and (max-width: 991px) {
    @content;
  }
}

@mixin display-flex-mixin() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin align-items-start-mixin() {
  -webkit-box-align: start;
  -moz-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

@mixin align-items-center-mixin() {
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

@mixin align-items-end-mixin() {
  -webkit-box-align: end;
  -moz-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

@mixin flex-direction-column-mixin() {
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

@mixin flex-direction-column-reverse-mixin() {
  -webkit-box-direction: reverse;
  -moz-box-direction: reverse;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

@mixin justify-content-space-between-mixin() {
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@mixin justify-content-center-mixin() {
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@mixin justify-content-flex-end-mixin() {
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@mixin align-self-start-mixin() {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

@mixin align-self-center-mixin() {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

@mixin flex-wrap-wrap-mixin() {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
