.chat-box {
  @include display-flex-mixin;

  &__users {
    flex: 0 1 30%;
    padding-right: 30px;
    overflow: auto;
    height: calc(100vh - 290px);

    .user-search {
      .searchable {
        display: block;
        float: none;
        margin: 0 0 10px 0;
        width: 100%;
      }
    }

    .chats {
      > div {
        &.new-message {
          font-weight: 700;
        }

        a {
          display: block;
        }
      }
    }
  }

  &__messages {
    @include display-flex-mixin;
    @include flex-direction-column-mixin;
    @include justify-content-flex-end-mixin;
    flex: 0 1 70%;
    //background-color: rgba($white, .8);
    height: calc(100vh - 240px);

    &-container {
      overflow: auto;
      padding: 30px 30px 0;

      .message {
        @include display-flex-mixin;
        @include flex-direction-column-mixin;

        .message {
          color: #FFFFFF;
          border-radius: 18px;
          padding: 8px 12px;
          line-height: 1.33;
        }

        &.message-left {
          align-items: flex-start;

          .message {
            background-color: rgba($malachite, .4);
            border-top-left-radius: 4px;
          }
        }

        &.message-right {
          align-items: flex-end;

          .message {
            align-self: flex-end;
            background-color: rgba($navy-blue, .4);
            border-top-right-radius: 4px;
          }
        }
      }
    }

    #invite-user {
      padding-left: 30px;

      .searchable {
        margin: 0;
      }
    }

    form {
      padding: 0 30px 30px;
      margin-bottom: 0;

      .form-fields {
        position: relative;
        margin-top: 15px;
        display: flex;
        border: 2px solid $very-light-grey;
        border-radius: 18px;

        .textarea-field {
          flex: 1 1 auto;
          padding: 10px 15px 10px 15px;
        }

        textarea {
          padding: 0;
          color: $ship-cove;
          display: block;
          width: 100%;
          font-size: 16px;
          line-height: 1;
          outline: none;
          resize: none;
          border: none;
          background: none;
          height: 20px;
          max-height: 100px;
        }

        input {
          margin: 0;
          font-size: 16px;
          background: -webkit-linear-gradient(0deg, #0cca5b, #0c85d6 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: 700;
          text-transform: uppercase;
          padding: 0 15px 0 0;
          border: none;
          outline: none;
        }

        .has-error {
          span {
            position: absolute;
            bottom: -25px;
            left: 0;
          }
        }
      }
    }
  }
}

.chatUsersMode {
  display: none;
  width: 330px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: fixed;
  background-color: $white;
  bottom: 0;
  right: 80px;
  z-index: 4;
  box-shadow: 0 12px 28px 0 $shadow-2,0 2px 4px 0 $shadow-1;
  overflow: hidden;

  &.opened {
    display: block;
  }

  &-top {
    position: relative;
    padding: 10px;
    display: flex;
    justify-content: flex-end;

    .close-chat {
      cursor: pointer;
    }
  }

  .chatMode-top {
    position: absolute;
    right: 0;

    svg {
      line {
        stroke: $white;
      }
    }
  }

  .chat-box {
    &__messages {
      height: calc(100% - 45px);
      background: #0cca5b;
      background: -moz-linear-gradient(-45deg, #0cca5b 0%, #0c85d6 100%);
      background: -webkit-linear-gradient(-45deg, #0cca5b 0%,#0c85d6 100%);
      background: linear-gradient(135deg, #0cca5b 0%,#0c85d6 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0cca5b', endColorstr='#0c85d6',GradientType=1 );

      &-container {
        padding: 0 15px 0;

        .message {
          font-size: 14px;
          margin: 10px 0;

          a {
            color: $white;
          }
        }
      }

      form {
        padding: 0 30px 15px 15px;

        .form-fields {
          input {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.chatMode {
  display: none;
  width: 330px;
  height: 450px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: fixed;
  background-color: $white;
  bottom: 0;
  right: 420px;
  z-index: 4;
  box-shadow: 0 12px 28px 0 $shadow-2,0 2px 4px 0 $shadow-1;
  overflow: hidden;

  &.opened {
    display: block;
  }

  &-top {
    position: relative;
    padding: 10px;
    display: flex;
    justify-content: flex-end;

    .close-chat {
      cursor: pointer;
    }
  }

  .chat-box {
    &__messages {
      height: calc(100% - 45px);

      &-container {
        padding: 0 15px 0;

        .message {
          font-size: 14px;
        }
      }

      #invite-user {
        padding-left: 15px;
        margin-top: 15px;
      }

      form {
        padding: 0 30px 15px 15px;

        .form-fields {
          input {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.chatIcon {
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 48px;
  height: 48px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  box-shadow: 0 2px 4px $shadow-1, 0 12px 28px $shadow-2;
  cursor: pointer;
  background: -webkit-linear-gradient(0deg, #0cca5b, #0c85d6 100%);
  color: $white;

  i {
    font-size: 20px;
    z-index: 4;
    margin-bottom: -2px;
    margin-right: -3px;
  }
}